var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[(!_vm.loading && _vm.isNullArray(_vm.newsPosts.data) == false)?_c('carousel-3d',{ref:"carousel",attrs:{"height":350,"autoplay":true,"autoplay-timeout":5000,"dir":'ltr',"controls-visible":true,"controls-prev-html":'&#10093;',"controls-next-html":'&#10092;',"controls-width":30,"controls-height":60,"on-main-slide-click":_vm.onSelect,"display":9}},_vm._l((_vm.newsPosts.data),function(slide,i){return _c('slide',{key:i,attrs:{"index":i},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var index = ref.index;
var isCurrent = ref.isCurrent;
var leftIndex = ref.leftIndex;
var rightIndex = ref.rightIndex;
return [_c('base-card',{attrs:{"color":"grey lighten-1","dark":""}},[_c('v-img',{class:{
              current: isCurrent,
              onLeft: leftIndex >= 0,
              onRight: rightIndex >= 0,
            },attrs:{"contain":"","src":_vm.baseImageUrl + slide.image_name,"lazy-src":"https://picsum.photos/10/6?image=15","gradient":'rgba(0, 0, 0, .20),rgba(0, 0, 0, .20)'},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('v-row',{staticClass:"fill-height text-right ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-chip',{staticClass:"mx-0 mb-2",attrs:{"label":"","color":"grey darken-3","text-color":"white","small":""}},[_vm._v(" "+_vm._s(slide.tag.name)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm._f("moment")(slide.date,"dddd, MMMM Do YYYY"))+" ")])],1),_c('v-col',{attrs:{"align-self":"end"}},[_c('v-chip',{staticClass:"mb-3",attrs:{"color":"primary","label":"","small":"","href":'post/' + slide.id,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" التفاصيل ")])],1)],1)],1)],1)]}}],null,true)})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }