<template>
  <v-col cols="12">
    <carousel-3d
      ref="carousel"
      v-if="!loading && isNullArray(newsPosts.data) == false"
      :height="350"
      :autoplay="true"
      :autoplay-timeout="5000"
      :dir="'ltr'"
      :controls-visible="true"
      :controls-prev-html="'&#10093;'"
      :controls-next-html="'&#10092;'"
      :controls-width="30"
      :controls-height="60"
      :on-main-slide-click="onSelect"
      :display="9"
    >
      <slide v-for="(slide, i) in newsPosts.data" :index="i" :key="i">
        <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
          <!-- <v-img  :data-index="index"
                      :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }"
                      :src="baseImageUrl + slide.image_name"></v-img> -->
          <base-card color="grey lighten-1" dark>
            <v-img
              contain
              :class="{
                current: isCurrent,
                onLeft: leftIndex >= 0,
                onRight: rightIndex >= 0,
              }"
              :src="baseImageUrl + slide.image_name"
              :lazy-src="`https://picsum.photos/10/6?image=15`"
              :gradient="'rgba(0, 0, 0, .20),rgba(0, 0, 0, .20)'"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-row class="fill-height text-right ma-0">
                <v-col cols="12">
                  <v-chip
                    label
                    class="mx-0 mb-2"
                    color="grey darken-3"
                    text-color="white"
                    small
                  >
                    {{ slide.tag.name }}
                  </v-chip>

                  <!-- <h3 class="title font-weight-bold mb-2">
                  {{ post.title }}
                </h3> -->

                  <div class="caption">
                    <!-- {{ post.author }}
              <br> -->
                    {{ slide.date | moment("dddd, MMMM Do YYYY") }}
                  </div>
                </v-col>

                <v-col align-self="end">
                  <v-chip
                    class="mb-3"
                    color="primary"
                    label
                    small
                    @click.stop=""
                    :href="'post/' + slide.id"
                    target="_blank"
                  >
                    التفاصيل
                  </v-chip>
                </v-col>
              </v-row>
            </v-img>
          </base-card>
        </template>
      </slide>
    </carousel-3d>
  </v-col>
</template>

<script>
// Utilities
import { BASE_IMAGE_URL } from "@/constants/index";
import { mapGetters } from "vuex";

export default {
  name: "NewsCarousel",

  data: () => ({
    page: 1,
    baseImageUrl: BASE_IMAGE_URL,
    sl: null,
  }),
  computed: {
    ...mapGetters(["newsPosts", "loading"]),
  },
  methods: {
    onSelect() {
      //console.log("current slide click");

      console.log(this.$refs.carousel.currentIndex);
      //this.$router.push('/posts')
    },
  },
};
</script>
